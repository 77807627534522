<template>
  <div class="row">
    <div class="col-12">
      <div class="integration__header">
        <div class="d-flex flex-column">
          <h3 class="mb-1 emm__page-heading">Integrations</h3>
          <span class="emm__page-sub-heading">Supercharge your leads and connect with tools that you use
            everyday</span>
        </div>
      </div>
      <div class="row integration__filters-wrapper mt-5 d-flex justify-content-between align-items-start">
        <div class="col-7 integration-filters d-flex align-items-center justify-content-start">
          <div
            v-for="type in ['all', 'Communication', 'Mail']"
            :key="type"
            :class="['filter-item-integration', { active: selectedType === type }]"
            @click="selectedType = type"
          >
            {{ type.charAt(0).toUpperCase() + type.slice(1) }}
          </div>
          </div>
        <div class="col-5 emm__integration-serach-input-wrapper">
          <base-input>
            <el-input type="search" class="search-input mb-0 input__integrations" clearable prefix-icon="el-icon-search"
              placeholder="Search integration" v-model="searchQuery" aria-controls="datatables">
            </el-input>
          </base-input>
        </div>
      </div>
      <div class="enn__integration__list-wrapper mt-5 d-flex flex-column">
        <!-- <div class="p-2 rounded-2" :style="{background:this.$global.globalBoxBgColor}">
          hello
        </div> -->
        <div v-if="filteredIntegrations.length == 0" style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
          <img src="/img/empty-box.png" alt="empty" style="max-height: 400px; max-width: 400px;" />
          <p style="text-align: center;">Data not found.</p>
        </div>
        <card v-for="(item, i) in filteredIntegrations" :key="item.slug" class="rounded-2 cursor-pointer col-12 mb-0">
          <div @click.stop="redirectToDetailsPage(item.slug,item.name)"
            class="'p-2 d-flex justify-content-between align-items-center'">

            <div class="d-flex align-items-center emm-integration-item justify-content-between w-100">
              <div class="w-75 d-flex align-items-center" style="gap:16px">
                <i v-if="item.slug == 'mailboxpower'" class="fa-regular fa-envelope-open" style="font-size: 1.6rem; color: #0091ff;"></i>
                <i v-else-if="item.slug == 'clickfunnels'" class="fas fa-mouse-pointer" style="font-size: 1.6rem; color: #0091ff;"></i>
                <img v-else class="logo-wrap" :src="item.img" alt="brand-logo" />
                <div>
                  <h5 class="mb-0 integration-brand-name">{{ item.name }}</h5>
                  <span class="brand-sub-heading">{{ item.description }}</span>
                </div>
              </div>
              <div v-if="item.active"
                class="emm__integration-status-wrapper border rounded p-1 d-flex align-items-center">
                <span class="integration-status-indicator rounded-pill  bg-success d-block "></span>
                <span class="status-text">{{ item.active }} Active</span>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
      selectedType: 'all',
      integrations: [
        {
          name: 'Twilio SendGrid',
          description: 'Deliver Exceptional Email Experiences with SendGrid',
          img: 'https://emmspaces.nyc3.cdn.digitaloceanspaces.com/systems/sendgrid-logo.png',
          type: 'Mail',
          slug: 'sendgrid'
        },
        {
          name: 'Lead Connector',
          description: 'Get Better Clicks & Engagement - Enhance Your Email Campaigns.',
          img: 'https://emmspaces.nyc3.cdn.digitaloceanspaces.com/systems/large-leadconnector.png',
          type: 'Communication',
          slug: 'leadconnector'
        }, 
        {
          name: 'Kartra',
          description: 'Streamline your marketing with an all-in-one marketing platform',
          img: 'https://emmspaces.nyc3.cdn.digitaloceanspaces.com/systems/kartralogo.jpeg',
          type: 'Communication',
          slug: 'kartra'
        },
        {
          name: 'Zapier/Webhook',
          description: 'The easiest way to automate you work',
          img: 'https://emmspaces.nyc3.cdn.digitaloceanspaces.com/systems/webhooklink.png',
          type: 'Communication',
          slug: 'zapier'
        },
        {
          name: 'MailBox Power',
          description: 'Get Better Clicks & Engagement - Enhance Your Email Campaigns.',
          img: '',
          type: 'Communication',
          slug: 'mailboxpower'
        },
        {
          name: 'ClickFunnels',
          description: 'Build High-Converting Sales Funnels - Turn Visitors into Customers Easily.',
          img: '',
          type: 'Communication',
          slug: 'clickfunnels'
        },
      ]
    }
  },
  methods: {
    redirectToDetailsPage(slug,name) {
      this.$router.push(`/integrations/${slug}/${encodeURIComponent(name)}`)
    },
  },
  computed: {
    filteredIntegrations() {
      const searchQuery = this.searchQuery.toLowerCase();
      return this.integrations.filter(integration => {
        const matchesType = this.selectedType === 'all' || integration.type === this.selectedType;
        const matchesSearch = integration.name.toLowerCase().includes(searchQuery);
        return matchesType && matchesSearch;
      }
      );
    }
  }
};
</script>

<style lang="scss">
.integration-filters {
  gap: 16px;

  .filter-item-integration {
    cursor: pointer;
  }

  .emm__integration-serach-input-wrapper {
    flex: 1;

    .form-group {
      margin-bottom: 0 !important;
    }
  }
}

.enn__integration__list-wrapper {
  gap: 16px;
}

.emm-integration-item {
  gap: 16px;
  cursor: pointer;
}

.logo-wrap {
  height: 32px;
  width: 32px;
  object-fit: cover;
  flex-shrink: 0;
}

.integration-brand-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  font-style: normal;
}

.brand-sub-heading {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  font-style: normal;
}

.emm__page-heading {
  font-size: 30px;
  font-weight: 600;
  line-height: 100%;
  font-style: normal;
}

.emm__page-sub-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  font-style: normal;
}

.emm__integration-status-wrapper {
  gap: 8px;
  cursor: pointer;

  .status-text {
    font-size: 12px;
  }
}

.integration-status-indicator {
  height: 14px;
  width: 14px;
}

.input__integrations .el-input__inner {
  padding-left: 30px;
  padding-right: 30px;
}

.filter-item-integration {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.filter-item-integration:hover {
  background-color: var(--text-btn-black);
  color: black;
}

.filter-item-integration.active {
  background-color: var(--text-btn-black);
  color: black;
}
</style>